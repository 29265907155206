import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from './assets/js/axios.js'
import Cookies from "js-cookie";
import 'highlight.js/styles/github.css';
import VueYoutube from 'vue-youtube';
import Meta from 'vue-meta'
Vue.use(Meta)
Vue.use(VueYoutube);
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$cookies = Cookies;

// // 挂载路由导航守卫,实现页面访问权限
// router.beforeEach((to, from, next) => {
//   // to将要访问的路径
//   // from代表从那个路径跳转而来
//   // next是一个函数，表示放行
//   const nextpath = ['/','/login']
//   if (nextpath.includes(to.path)) {
//     return next()
//   } else {
//     // 获取token
//     const subid = Cookies.get("subid");

//     // const tokenStr = window.sessionStorage.getItem('token')
//     if (!subid) {
//       // alert("请先登录！")
//       return next('/login')
//     } else {
//       return next()
//     }
//   }
// })

new Vue({
  ElementUI,
  router,  // 注入路由
  render: h => h(App)
}).$mount('#app')