<template>
    <div>
        <div class="button-row">
            <el-button @click="handleToggleRecording" :type="isRecording ? 'danger' : 'primary'">
                {{ isRecording ? '停止录音' : '开始录音' }}
            </el-button>
            <el-button @click="handlePauseRecording" :disabled="!isRecording">
                {{ isPaused ? '继续录音' : '暂停录音' }}
            </el-button>
            <el-button @click="handlePlay" :disabled="!hasRecording">播放录音</el-button>
            <el-button @click="handleClear" :disabled="!hasRecording">清除录音</el-button>
            <el-button @click="handleDownload" :disabled="!hasRecording">下载录音</el-button>
            <el-button @click="handleUpload" :disabled="!hasRecording">上传录音</el-button>
        </div>
        <div class="button-row">
            <el-button @click="handleToggleRecognition" :type="isRecognizing ? 'danger' : 'primary'"
                :disabled="!isRecording">
                {{ isRecognizing ? '停止识别' : '开始识别' }}
            </el-button>
            <el-button @click="handlePauseRecognition" :disabled="!isRecognizing">
                {{ isRecognitionPaused ? '继续识别' : '暂停识别' }}
            </el-button>
        </div>

        <div class="textbox">
            <h3>识别结果：</h3>
            <ul>
                <li v-for="(txt, i) in alltext" :key="i">{{ txt }}</li>
            </ul>
            <p>{{ websocket_audio2txt_result_msg }}</p>
        </div>
    </div>
</template>

<script>
import Recorder from 'js-audio-recorder';

export default {
    data() {
        return {
            recorder: null,
            faq_token: "f8125107e8b64ecbbcb85c14a59158f5",
            faq_appkey: "zEURyO5izWCp17u9",
            websocket_audio2txt_result_msg: "",
            alltext: [],
            websock: null,
            websocket_task_id: "",
            isRecording: false,
            hasRecording: false,
            audioTimer: null,
            audioOffset: 0,
            lastSendTime: 0,
            isPaused: false,
            isRecognizing: false,
            isRecognitionPaused: false,
            audioChunks: [],
            maxAudioLength: 60 * 16000 * 2,
        }
    },
    created() {
        this.getPermission();
    },
    beforeDestroy() {
        this.stopRecording();
        this.closeWebSocket();
        this.audioChunks = [];
    },
    methods: {
        getPermission() {
            Recorder.getPermission().then(() => {
                console.log("获取麦克风权限成功");
            }, (error) => {
                console.error("获取麦克风权限失败:", error);
            });
        },
        handleToggleRecording() {
            if (this.isRecording) {
                this.stopRecording();
            } else {
                this.startRecording();
            }
        },
        handlePauseRecording() {
            console.log("暂停录音", this.isRecording, !this.isPaused);
            if (this.isRecording && !this.isPaused) {
                this.recorder.pause();
                this.isPaused = true;
                console.log("录音暂停");
            } else if (this.isRecording && this.isPaused) {
                this.recorder.resume();
                this.isPaused = false;
                console.log("录音恢复");
            }
        },
        handlePlay() {
            if (this.recorder && this.hasRecording) {
                this.recorder.play();
            }
        },
        handleClear() {
            this.recorder = null;
            this.websocket_audio2txt_result_msg = "";
            this.alltext = [];
            this.hasRecording = false;
            this.lastSendTime = 0;
            console.log("录音和识别内容已清除");
        },
        handleDownload() {
            if (this.recorder && this.hasRecording) {
                const url = URL.createObjectURL(this.recorder.getWAVBlob());
                const a = document.createElement('a');
                a.href = url;
                a.download = 'recording.wav';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        async handleUpload() {
            if (this.recorder && this.hasRecording) {
                const formData = new FormData();
                formData.append('audio', this.recorder.getWAVBlob(), 'recording.wav');
                formData.append('text', this.alltext.join('\n'));

                try {
                    const response = await this.$axios.post('/api/hello', formData);
                    console.log('上传成功:', response.data);
                } catch (error) {
                    console.error('上传失败:', error);
                }
            }
        },
        startRecording() {
            console.log("开始录音...");
            this.websocket_audio2txt_result_msg = "";
            this.recorder = new Recorder({
                sampleBits: 16,
                sampleRate: 16000,
                numChannels: 1,
                compiling: false,
            });
            this.recorder.start();
            this.isRecording = true;
            this.isPaused = false;
            this.startAudioCapture();
        },
        stopRecording() {
            console.log("停止录音...");
            if (this.recorder) {
                this.recorder.stop();
                this.isRecording = false;
                this.hasRecording = true;
            }
            if (this.isRecognizing) {
                this.stopRecognition();
            }
            if (this.audioTimer) {
                clearTimeout(this.audioTimer);
                this.audioTimer = null;
            }
        },
        startAudioCapture() {
            if (this.isRecording && !this.isPaused) {
                const pcmData = this.recorder.getPCMBlob();
                if (this.isRecognizing && !this.isRecognitionPaused) {
                    this.sendAudioChunk(pcmData);
                }
                this.audioTimer = setTimeout(() => this.startAudioCapture(), 500);
            }
        },
        handleToggleRecognition() {
            if (this.isRecognizing) {
                this.stopRecognition();
            } else {
                this.startRecognition();
            }
        },
        handlePauseRecognition() {
            if (this.isRecognizing && !this.isRecognitionPaused) {
                this.isRecognitionPaused = true;
                console.log("识别暂停");
            } else if (this.isRecognizing && this.isRecognitionPaused) {
                this.isRecognitionPaused = false;
                console.log("识别恢复");
            }
        },
        startRecognition() {
            if (!this.isRecording) {
                console.log("请先开始录音");
                return;
            }
            this.initWebSocket();
            this.isRecognizing = true;
            this.isRecognitionPaused = false;
        },
        stopRecognition() {
            this.websocketSendStop();
            this.closeWebSocket();
            this.isRecognizing = false;
            this.isRecognitionPaused = false;
        },
        sendAudioChunk(blob) {
            if (this.websock && this.websock.readyState === WebSocket.OPEN) {
                const reader = new FileReader();
                reader.onload = () => {
                    const arrayBuffer = reader.result;
                    const dataView = new DataView(arrayBuffer);
                    const newDataView = new DataView(arrayBuffer, this.lastSendTime, dataView.byteLength - this.lastSendTime);
                    this.lastSendTime = dataView.byteLength;
                    const newBlob = new Blob([newDataView], { type: 'audio/pcm' });
                    this.websock.send(newBlob);
                };
                reader.readAsArrayBuffer(blob);
            }
        },
        initWebSocket() {
            const wsuri = `wss://nls-gateway.cn-shanghai.aliyuncs.com/ws/v1?token=${this.faq_token}`;
            this.websock = new WebSocket(wsuri);
            this.websock.onmessage = this.websocketOnMessage;
            this.websock.onopen = this.websocketOnOpen;
            this.websock.onerror = this.websocketOnError;
            this.websock.onclose = this.websocketClose;
        },
        getRandomStrNum() {
            return [...Array(32)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
        },
        websocketOnOpen() {
            console.log("WebSocket连接已建立");
            if (!this.websocket_task_id) { this.websocket_task_id = this.getRandomStrNum(); }

            let actions = {
                "header": {
                    "message_id": this.getRandomStrNum(),
                    "task_id": this.websocket_task_id,
                    "namespace": "SpeechTranscriber",
                    "name": "StartTranscription",
                    "appkey": this.faq_appkey,
                },
                "payload": {
                    "format": "PCM",
                    "sample_rate": 16000,
                    "enable_intermediate_result": true,
                    "enable_punctuation_prediction": true,
                    "enable_inverse_text_normalization": true,
                    "enable_ignore_sentence_timeout": true,
                    "enable_words": true,
                    "max_sentence_silence": 2000,
                }
            };
            this.websocketSend(JSON.stringify(actions));
        },
        websocketSendStop() {
            let actions = {
                "header": {
                    "message_id": this.getRandomStrNum(),
                    "task_id": this.websocket_task_id,
                    "namespace": "SpeechTranscriber",
                    "name": "StopTranscription",
                    "appkey": this.faq_appkey,
                }
            };
            this.websocketSend(JSON.stringify(actions));
        },
        websocketOnError(error) {
            console.error("WebSocket连接错误:", error);
        },
        websocketOnMessage(e) {
            const redata = JSON.parse(e.data);
            if (redata.header.name === 'TranscriptionResultChanged') {
                console.log('实时识别结果:', redata.payload);
                this.websocket_audio2txt_result_msg = redata.payload.result;
            } else if (redata.header.name === 'SentenceEnd') {
                console.log('一句话结束:', redata.payload);
                this.websocket_audio2txt_result_msg = redata.payload.result;
                this.alltext.push(this.websocket_audio2txt_result_msg);
            }
        },
        websocketSend(Data) {
            if (this.websock && this.websock.readyState === WebSocket.OPEN) {
                this.websock.send(Data);
            } else {
                console.log('WebSocket未连接');
            }
        },
        websocketClose(e) {
            console.log('WebSocket连接已关闭', e);
        },
        closeWebSocket() {
            if (this.websock) {
                this.websock.close();
                this.websock = null;
            }
        },
    }
}
</script>

<style lang="less" scoped>
.button-row {
    margin-bottom: 10px;
}

.textbox {
    color: #fff;
    margin-top: 20px;
}
</style>