<template>
  <div class="record-lecture">
    <div class="backhome" @click="backhomepage"></div>
    <h1>
      <i class="el-icon-microphone"></i>
      Record Lecture
    </h1>
    <p>Make sure the recording is longer than <span style="color: red;font-size: 1.6vw;">1</span> minute.</p>
    <p>Time Max : 180minutes</p>
    <div class="notradio" v-show="radiostatus == 'notradio'">
      <div @click="startRecording()" class="record-button1">
        <i class="el-icon-microphone"></i>
      </div>

    </div>
    <div class="startradio" v-show="radiostatus == 'startradio'">
      <div class="waveform">
        <canvas ref="waveformCanvas" width="600" height="100"></canvas>
      </div>
      <div class="timer">{{ formattedTime }}</div>
      <div class="controls">
        <div @click="toggleRecording()" class="record-button">
          <i v-if="isRecording" class="el-icon-video-pause"></i>
          <i v-else class="el-icon-microphone record-button-microphone"></i>
        </div>
        <div @click="stopRecording" class="stop-button" :disabled="!audioUrl">
          <i></i>
        </div>
        <div @click="clearRecording" class="clear-button" :disabled="!audioUrl">
          Clear
        </div>
      </div>
    </div>
    <div class="stopradio" v-show="radiostatus == 'stopradio'">
      <div class="waveform">
        <canvas ref="waveformCanvas_R" width="600" height="100"></canvas>
        <audio ref="audioPlayer" :src="audioUrl" @timeupdate="onTimeUpdate" @ended="onAudioEnded"></audio>
      </div>
      <div>
        <span>{{ formatTime(currentTime) }} / {{ formatTime(elapsedTime) }}</span>
      </div>
      <div class="controls">
        <div @click="togglePlay()" class="record-button">
          <i v-if="!isPlaying" class="el-icon-video-play"></i>
          <i v-else class="el-icon-video-pause"></i>
        </div>
        <div @click="clearRecording" class="clear-button" :disabled="!audioUrl">
          Clear
        </div>

      </div>
      <div class="overaudiobox">

        <button v-loading="isloading" element-loading-spinner="el-icon-loading" :element-loading-text="loadingText"
          element-loading-background="rgba(21, 23, 32, 0.8)" @click="generateNotes" class="generate-notes-button"
          :disabled="!audioUrl">
          Generate Notes
          <i class="el-icon-arrow-right"></i>
        </button>
        <span :class="{ tipred: true, shake: tipredshow }" v-if="tipredshow">The recording is less than 1 minute</span>
        <span :class="{ tipred: true, shake: uploadstatus == 'error' }" v-if="uploadstatus == 'error'"><i
            class="el-icon-warning"></i>Upload fail. Save audio locally. <br> Retry later
          with a better network connection. </span>


        <el-button  @click="downloadAudio" class="clear-button download-button" :disabled="!audioUrl || uploadstatus=='uploading'">
          Save audio locally
        </el-button>

      </div>

    </div>
    <div class="tipbox">
      Tips:<br>Do not close the tab while your recording is in progress. <br>
      For best results, please record in a quiet area and position your device close to the sound.
    </div>
  </div>
</template>

<script>
import IndexedDBAudioStorage from "@/assets/js/indexedDB.js";
export default {
  props: {
    uploadProgress: {
      type: Number,
      default: 0,
    },
    uploadstatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isRecording: false,
      isPaused: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      startTime: 0,
      elapsedTime: 0,
      pauseTime: 0,
      timer: null,
      audioContext: null,
      analyser: null,
      dataArray: null,
      canvasContext: null,
      animationFrame: null,
      // notradio  stopradio
      radiostatus: "notradio",
      isPlaying: false,
      currentTime: 0,
      progress: 0,
      playbackAnalyser: null,
      playbackDataArray: null,
      playbackAnimationFrame: null,
      playaudioContext: null,
      audioSource: null,

      isloading: false,
      tipredshow: false,


      chunks: [],
      chunkInterval: 60000, // 60 seconds
      chunkCounter: 0,
      maxChunks: 180, // 3 hours (180 minutes)
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.elapsedTime / 60);
      const seconds = this.elapsedTime % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    loadingText() {
      return `Uploading... ${this.uploadProgress}%`;
    },
  },
  mounted() {
    this.canvasContext = this.$refs.waveformCanvas.getContext("2d");
    this.playbackCanvasContext = this.$refs.waveformCanvas_R.getContext("2d");
  },
  methods: {
    backhomepage() {
      this.$emit("backhomepage");
    },
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.audioPlayer.pause();
        cancelAnimationFrame(this.playbackAnimationFrame);
      } else {
        //
        this.$refs.audioPlayer
          .play()
          .then(() => {
            // this.setupPlaybackAnalyser();
            // this.drawPlaybackWaveform();
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
          });
      }
      this.isPlaying = !this.isPlaying;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    onAudioEnded() {
      this.isPlaying = false;
      cancelAnimationFrame(this.playbackAnimationFrame);
    },
    onTimeUpdate(e) {
      this.currentTime = e.target.currentTime;
    },
    async toggleRecording() {
      if (!this.isRecording && !this.isPaused) {
        await this.startRecording();
      } else if (this.isRecording && !this.isPaused) {
        this.pauseRecording();
      } else if (this.isPaused) {
        this.resumeRecording();
      }
    },
    async startRecording() {
      if (this.radiostatus != "startradio") {
        this.radiostatus = "startradio";
      }
      try {
        // 获取用户音频设备
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        // 创建音频上下文
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        // 创建媒体流源
        const source = this.audioContext.createMediaStreamSource(stream);
        // 创建音频分析器
        this.analyser = this.audioContext.createAnalyser();
        // 设置FFT大小
        this.analyser.fftSize = 2048;
        // 将媒体流源连接到音频分析器
        source.connect(this.analyser);
        // 创建用于存储音频数据的数组
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        // 创建媒体录制器
        this.mediaRecorder = new MediaRecorder(stream);
        // 监听录音数据可用的事件
        this.mediaRecorder.addEventListener("dataavailable", async (event) => {
          // this.audioChunks.push(event.data);
          if (event.data.size > 0) {
            this.chunks.push(event.data);
            await this.saveChunk(event.data);
            this.chunkCounter++;
          }

          if (this.chunkCounter >= this.maxChunks) {
            this.stopRecording();
          } else if (this.isRecording) {
            setTimeout(() => {
              if (this.isRecording && this.mediaRecorder.state === 'recording') {
                this.mediaRecorder.stop();
                this.mediaRecorder.start();
              }
            }, this.chunkInterval);
          }
        });

        // 监听录音停止的事件
        this.mediaRecorder.addEventListener("stop", () => {
          // 将音频数据转换为Blob对象
          // const audioBlob = new Blob(this.audioChunks);
          // // 创建音频URL
          // this.audioUrl = URL.createObjectURL(audioBlob);

          if (this.isRecording) {
            this.mediaRecorder.start();
          } else {
            this.finalizeRecording();
          }

          // this.finalizeRecording();
        });

        // // 开始录音
        // this.mediaRecorder.start();
        // // 设置录音状态为正在录音
        // this.isRecording = true;
        // this.isPaused = false;

        // 开始录音，设置chunk间隔
        this.mediaRecorder.start();
        this.isRecording = true;
        this.isPaused = false;


        // 记录录音开始时间
        if (this.pauseTime != 0) {
          this.startTime += Date.now() - this.pauseTime; // Adjust startTime
          this.pauseTime = 0;
        } else {
          this.startTime = Date.now();
        }

        // 设置定时器，每秒更新已录制的时长
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
          if (this.elapsedTime > 180 * 60) {
            // 超过10秒，停止录音
            this.stopRecording();
          }
        }, 1000);

        // 绘制波形图
        this.drawWaveform();
      } catch (err) {
        // 如果获取麦克风失败，输出错误信息
        console.error("Error accessing microphone:", err);
      }
    },
    pauseRecording() {
      if (this.isRecording && !this.isPaused) {
        this.mediaRecorder.pause();
        this.isPaused = true;
        this.isRecording = false;
        clearInterval(this.timer);
        this.pauseTime = Date.now();
        cancelAnimationFrame(this.animationId);
        // this.audioContext
        //   .suspend()
        //   .catch((error) =>
        //     console.error("Error suspending audio context:", error)
        //   );
      }
    },
    resumeRecording() {
      if (this.isPaused) {
        this.mediaRecorder.resume();
        this.isPaused = false;
        this.isRecording = true;
        this.startTime += Date.now() - this.pauseTime;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
          if (this.elapsedTime > 180 * 60) {
            // 超过10秒，停止录音
            this.stopRecording();
          }
        }, 1000);
        this.audioContext
          .resume()
          .then(() => {
            this.drawWaveform();
          })
          .catch((error) =>
            console.error("Error resuming audio context:", error)
          );
      }
    },
    stopRecording() {
      this.radiostatus = "stopradio";
      this.pauseTime = 0;
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.isPaused = false;
      clearInterval(this.timer);
      cancelAnimationFrame(this.animationFrame);
      if (this.audioContext && this.audioContext.state !== "closed") {
        this.audioContext
          .close()
          .catch((error) =>
            console.error("Error closing audio context:", error)
          );
      }
      this.audioContext = null;
    },
    async clearRecording() {
      this.audioUrl = null;
      this.elapsedTime = 0;
      this.tipredshow = false;
      this.pauseTime = 0;
      this.isPlaying = false;
      this.isPaused = false;
      this.currentTime = 0;
      this.progress = 0;
      this.mediaRecorder = null;
      this.audioChunks = [];
      this.$parent.uploadstatus = ''
      // 清理录音相关资源
      this.canvasContext.clearRect(
        0,
        0,
        this.$refs.waveformCanvas.width,
        this.$refs.waveformCanvas.height
      );

      // 清理 IndexedDB 中的数据
      await IndexedDBAudioStorage.clearDB();

      if (this.audioContext && this.audioContext.state !== "closed") {
        this.audioContext
          .close()
          .catch((error) =>
            console.error("Error closing audio context:", error)
          );
      }
      if (this.analyser) {
        this.analyser.disconnect();
        this.analyser = null;
      }
      this.cleanupAudioContext();
      this.radiostatus = "notradio";
    },
    cleanupAudioContext() {
      // 清理播放相关资源
      if (this.audioSource) {
        this.audioSource.disconnect();
        this.audioSource = null;
      }
      if (this.playbackAnalyser) {
        this.playbackAnalyser.disconnect();
        this.playbackAnalyser = null;
      }
      // 清理 AudioContext
      if (this.playaudioContext && this.playaudioContext.state !== "closed") {
        this.playaudioContext
          .close()
          .catch((error) =>
            console.error("Error closing audio context:", error)
          );
      }

      this.$refs?.audioPlayer?.pause();

      this.playaudioContext = null;
      // 重置其他相关状态
      this.playbackDataArray = null;

      // 停止所有动画帧
      cancelAnimationFrame(this.animationFrame);
      cancelAnimationFrame(this.playbackAnimationFrame);
    },
    async downloadAudio() {
      if (this.audioUrl) {
        const a = document.createElement("a");
        a.href = this.audioUrl;
        a.download = "audio_" + Date.now() + ".webm";
        a.click();
      }
      // await IndexedDBAudioStorage.clearDB();
    },
    async generateNotes() {

      const chunks = await IndexedDBAudioStorage.getAllChunks();
      if (!chunks) {
        console.error("No recording to upload");
        return;
      }

      if (this.elapsedTime < 60) {
        this.tipredshow = true;
        return;
      }
      this.isloading = true;
      const audioBlob = new Blob(chunks);
      const name = "audio_" + Date.now() + ".mp3";

      // 清理 IndexedDB 中的数据
      await IndexedDBAudioStorage.clearDB();
      console.log("Recording finalized and IndexedDB cleared");


      this.$emit("upload_audio", 'radio',audioBlob, name);


    },
    setupPlaybackAnalyser() {
      if (!this.playaudioContext || this.playaudioContext.state === "closed") {
        this.playaudioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.audioSource = null;
        this.playbackAnalyser = null;
      }

      if (this.playaudioContext.state === "suspended") {
        this.playaudioContext.resume();
      }

      if (!this.playbackAnalyser) {
        this.playbackAnalyser = this.playaudioContext.createAnalyser();
        this.playbackAnalyser.fftSize = 2048;
      }

      if (!this.audioSource) {
        this.audioSource = this.playaudioContext.createMediaElementSource(
          this.$refs.audioPlayer
        );
        this.audioSource.connect(this.playbackAnalyser);
        this.playbackAnalyser.connect(this.playaudioContext.destination);
      }

      this.playbackDataArray = new Uint8Array(
        this.playbackAnalyser.frequencyBinCount
      );
    },
    drawPlaybackWaveform() {
      if (!this.playbackAnalyser) return;

      this.playbackAnalyser.getByteTimeDomainData(this.playbackDataArray);

      const canvas = this.$refs.waveformCanvas_R;
      const canvasCtx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;

      canvasCtx.fillStyle = "#15171f";
      canvasCtx.fillRect(0, 0, width, height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#ff4444";
      canvasCtx.beginPath();

      const sliceWidth = (width * 1.0) / this.playbackDataArray.length;
      let x = 0;

      for (let i = 0; i < this.playbackDataArray.length; i++) {
        const v = this.playbackDataArray[i] / 128.0;
        const y = (v * height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(width, height / 2);
      canvasCtx.stroke();

      if (this.isPlaying) {
        this.playbackAnimationFrame = requestAnimationFrame(
          this.drawPlaybackWaveform
        );
      }
    },
    drawWaveform() {

      if (!this.analyser) return;

      this.analyser.getByteTimeDomainData(this.dataArray);
      this.canvasContext.fillStyle = "#15171f";
      this.canvasContext.fillRect(
        0,
        0,
        this.$refs.waveformCanvas.width,
        this.$refs.waveformCanvas.height
      );

      this.canvasContext.lineWidth = 2;
      this.canvasContext.strokeStyle = "#ff4444";
      this.canvasContext.beginPath();

      const sliceWidth =
        (this.$refs.waveformCanvas.width * 1.0) /
        this.analyser.frequencyBinCount;
      let x = 0;

      for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
        const v = this.dataArray[i] / 128.0;
        const y = (v * this.$refs.waveformCanvas.height) / 2;

        if (i === 0) {
          this.canvasContext.moveTo(x, y);
        } else {
          this.canvasContext.lineTo(x, y);
        }

        x += sliceWidth;
      }

      this.canvasContext.lineTo(
        this.$refs.waveformCanvas.width,
        this.$refs.waveformCanvas.height / 2
      );
      this.canvasContext.stroke();

      this.animationFrame = requestAnimationFrame(this.drawWaveform);
    },

    async saveChunk(chunk) {
      try {
        await IndexedDBAudioStorage.saveChunk(chunk);
        console.log("Chunk saved successfully");
      } catch (error) {
        console.error("Error saving chunk:", error);
        // 可以在这里添加错误处理逻辑，比如通知用户
        // this.$message.error("保存录音片段时出错，请检查您的设备存储空间。");
      }
    },

    async finalizeRecording() {
      try {
        const chunks = await IndexedDBAudioStorage.getAllChunks();
        const audioBlob = new Blob(chunks);
        this.audioUrl = URL.createObjectURL(audioBlob);

        // 如果需要，可以在这里触发上传或进一步处理的逻辑
        // this.uploadAudio(audioBlob);
      } catch (error) {
        console.error("Error finalizing recording:", error);
        // this.$message.error("处理录音时出错，请稍后重试。");
      }
    },
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationFrame);
    cancelAnimationFrame(this.playbackAnimationFrame);
    // 确保在组件销毁前清理 IndexedDB
    IndexedDBAudioStorage.clearDB().catch(console.error);
    if (this.audioSource) {
      this.audioSource.disconnect();
    }

    if (this.playbackAnalyser) {
      this.playbackAnalyser.disconnect();
    }
    this.clearRecording();
  },
};
</script>

<style lang="less" scoped>
.record-lecture {
  color: white;
  padding: 20px;
  text-align: center;

  .backhome {
    cursor: pointer;
    width: 11vw;
    height: 15vh;
    position: absolute;
    left: 1vw;
    top: 1vw;
  }
}

.tipbox {
  font-size: 0.9vw;
  text-align: center;
  margin: 4vw auto 0;
}

h1 {
  font-size: 34px;
  margin-bottom: 10px;

  i {
    padding: 10px;
    font-size: 40px;
    background-color: #ff4f4f;
    border-radius: 50%;
  }
}

p {
  font-size: 18px;
  color: rgb(209 213 219);
}

.notradio {
  .record-button1 {
    cursor: pointer;
    background-color: #fff;
    display: inline-block;
    padding: 10px;
    border-radius: 50%;
    margin-top: 5vw;

    i {
      font-size: 38px;
      color: #15171f;
    }

    &:hover {
      background-color: #555658;

      i {
        font-size: 38px;
        color: #fff;
      }
    }
  }
}

.waveform {
  width: 30%;
  height: 100px;
  margin: 20px auto;
  overflow: hidden;
  /* 隐藏溢出的内容 */
  position: relative;

  /* 相对定位 */
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: 1;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, #15171f, transparent);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, #15171f, transparent);
  }
}

.clear-button {
  padding: 1vw 1.2vw;
  font-size: 17px;
  border-radius: 1vw;
  font-weight: bold;
  color: #ff4f4f;
  background-color: #fff;
  margin-left: 30px;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #f78080;
  }
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  .record-button {
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    i {
      font-size: 60px;
      color: #ff4f4f;
    }

    .record-button-microphone {
      color: #fff;
    }

    &:hover {
      i {
        opacity: 0.7;
      }
    }
  }

  .stop-button {
    background-color: #ff4f4f;
    padding: 15px 15px 13px;
    border-radius: 50%;
    border: 3px solid #ccc;
    cursor: pointer;
    margin-left: 30px;

    i {
      width: 20px;
      height: 20px;
      background-color: #fff;
      display: inline-block;
      border-radius: 7px;
    }

    &:hover {
      background-color: #e5a0a0;
    }
  }


}

.overaudiobox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2vw;

  .generate-notes-button {
    width: 16vw;
    padding: 1vw 0;
    font-size: 1.2vw;
    border-radius: 1vw;
    border: none;
    font-weight: bold;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(22 163 74);
    margin-bottom: 0.5vw;
    cursor: pointer;
    position: relative;
    justify-content: center;

    i {
      font-size: 1.2vw;
      position: absolute;
      right: 1vw;
    }

    &:hover {
      background-color: rgb(18 143 65);
    }
  }

  .download-button {
    width: 16vw;
    margin: 0;
    margin-top: 0.5vw;
    font-size: 1.2vw;
    padding: 1vw 1.2vw !important;
  }
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }
}

.tipred {
  display: inline-block;
  color: red;
  margin: 0.5vw 0;

  &.shake {
    animation: shake 0.3s;
  }
}
</style>
