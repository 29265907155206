<template>
  <div class="home-page">

    <div v-if="showhome !== ''">
      <!-- <button @click="uploadstatus= 'error'">ccc</button> -->
      <radiopage v-if="showhome == 'radio'" :uploadstatus="uploadstatus" :uploadProgress="uploadProgress"
        @backhomepage="backhomepage" @upload_audio="startUpload"></radiopage>
      <realtimeradiopage v-if="showhome == 'realtimradio'" :uploadProgress="uploadProgress" @backhomepage="backhomepage"
        @upload_audio="startUpload"></realtimeradiopage>
    </div>
    <div v-else>
      <section class="new-note">
        <div class="line1">
          <h2>Creat new notes</h2>
          <p v-if="false" @click="copyemail()">Feedback: easynote_ai@hotmail.com</p>
        </div>
        <p>Select your input</p>
        <div class="note-options">
          <div class="caozuo" @click="chooesSub('audio')">
            <!-- <i class="el-icon-s-help ired"></i> -->
            <img :src="audio_img" alt="" />
            <div class="textp">
              <h3>Record or Upload Audio</h3>
              <span>Upload an audio file</span>
            </div>
            <i class="iconr el-icon-arrow-right"></i>
          </div>
          <div class="caozuo" @click="chooesSub('video')">
            <!-- <i class="el-icon-video-camera-solid iblue"></i> -->
            <img :src="youtube_img" alt="" />
            <div class="textp">
              <h3>YouTube Video</h3>
              <span>Paste a YouTube link</span>
            </div>
            <i class="iconr el-icon-arrow-right"></i>
          </div>
          <div class="caozuo" @click="chooesSub('upload')">
            <!-- <i class="el-icon-upload"></i> -->
            <img :src="upload_img" alt="" />
            <div class="textp">
              <h3>Document Upload</h3>
              <span>PDF,PPT,WORD,EXCEL,CSV,TXT</span>
            </div>
            <i class="iconr el-icon-arrow-right"></i>
          </div>
        </div>
      </section>
      <section class="my-notes">
        <div class="titlebox">
          <h3 class="mynote">My notes</h3>
          <input type="text" v-model="searchQuery" placeholder="Search your history notes." />
        </div>

        <div class="note" v-show="sortedNotes.length > 0" @click="opennotes(note,'notes')" v-for="(note, i) in sortedNotes"
          :key="i">
          <div class="badge">{{ getCodeByName(note.language) }}</div>
          <span class="note-icon">
            <!-- <i class="el-icon-s-claim"></i> -->
            <img v-if="note.filetype == 'audio'" :src="audio_img" alt="" />
            <img v-if="note.filetype == 'youtube'" :src="youtube_img" alt="" />
            <img v-if="note.filetype == 'pdf'" :src="upload_img" alt="" />
          </span>
          <div class="content">
            <h3 :class="{ redcolor: note.status === 3 }">
              {{ note.notename }}
            </h3>
            <p>Created on {{ formatDateTime(note.createtime) }}</p>
          </div>
          <span class="errorinfo" v-if="note.status === 3">Failed</span>
          <span @click="opennotes(note, 'settings')" class="spanright"><i class="el-icon-more"></i></span>
        </div>
        <div class="error-message" v-show="notestats == 0">
          <img class="error-icon" :src="welcomeimg" />
          <p>Welcome! Click any option above to start creating your first note.</p>
        </div>
        <div class="error-message" v-show="notestats == 1">
          <img class="error-icon" :src="nonepdficon" />
          <p>Sorry, we couldn't find this pdf file. Please try again later.</p>
        </div>
        <div class="error-message" v-show="notestats == 2">
          <img class="error-icon" :src="loadingimg" />
          <p>loading...</p>
        </div>
      </section>
    </div>


    <popupupgrade v-if="isupgrade" :upgradedata="upgradedata" />
    <popupchaoxian ref="vipchaoxian" :textcontent="vipchaoxiantext" />
    <popupchaoxian ref="uploadchaoxian" :textcontent="uploadchaoxiantext" />

    <popupsubject @selectclass="selectclass" ref="modal" />
    <popupAudio ref="pAudio" @upload_audio="startUpload" @setrecordtitle="setrecordtitle"
      @chooeselanguage="chooeselanguage" @chooeselanguagein="chooeselanguagein" :uploadProgress="uploadProgress"
      :taskisloading="taskisloading" @home_chooseaside="home_chooseaside" />
    <popupVideo ref="pVideo" @chooeselanguage="chooeselanguage" @createnotevideo="createnotevideo"
      :uploadProgress="uploadProgress" :taskisloading="taskisloading" />
    <popupUpload @chooeselanguage="chooeselanguage" @upload_pdf="uploadFile" ref="pUpload"
      :uploadProgress="uploadProgress" :taskisloading_u="taskisloading_u" />
  </div>
</template>
<script>
import radiopage from "@/views/pages/home_page/radio_page.vue";
import realtimeradiopage from "@/views/pages/home_page/RealTimeSpeechRecognition.vue";
import popupAudio from "@/views/pages/home_page/popups/popup_audio.vue";
import popupVideo from "@/views/pages/home_page/popups/popup_video.vue";
import popupUpload from "@/views/pages/home_page/popups/popup_upload.vue";
import popupsubject from "@/views/pages/home_page/popups/popup_subjects.vue";
import popupupgrade from "@/views/pages/home_page/popups/popup_upgrade.vue";
import popupchaoxian from "@/views/pages/home_page/popups/popup_chaoxian.vue";
export default {
  name: "HomePage",
  components: {
    radiopage,
    realtimeradiopage,
    popupAudio,
    popupVideo,
    popupUpload,
    popupsubject,
    popupupgrade,
    popupchaoxian,

  },
  props: [],
  data() {
    return {
      // realtimradio    radio
      showhome: "",

      audio_img: require("@/assets/images/index/icons/1.png"),
      youtube_img: require("@/assets/images/index/icons/2.png"),
      upload_img: require("@/assets/images/index/icons/3.png"),
      nonepdficon: require("@/assets/images/home/none_pdf_icon.png"),
      welcomeimg: require("@/assets/images/home/welcome.png"),
      loadingimg: require("@/assets/images/noteindex/loading_img1.gif"),

      showpopupsubject: false,
      mynotes: [],
      chooseclass: "",
      chooesmodel: "",


      languages: [
        { code: "ZH", name: "Chinese" },
        { code: "ZH(T)", name: "Traditional Chinese (Hong Kong)" },
        { code: "EN", name: "English" },
        { code: "ES", name: "Spanish" },
        { code: "FR", name: "French" },
        { code: "DE", name: "German" },
        { code: "IT", name: "Italian" },
        { code: "JA", name: "Japanese" },
        { code: "RU", name: "Russian" },
        { code: "AR", name: "Arabic" },
        { code: "HI", name: "Hindi" },
        { code: "PT", name: "Portuguese" },
        { code: "NL", name: "Dutch" },
        { code: "KO", name: "Korean" },
        { code: "TR", name: "Turkish" },
      ],

      uploadchaoxiantext: "You have submitted too many tasks. Please try again later.",
      vipchaoxiantext: "Your free trial is almost over. <br> Please save your recordings before upgrade to premium to avoid losing them.",


      language: "",
      languagename: "",

      languagein: "",
      languagenamein: "",

      recordtitle: "",


      searchQuery: "",

      isloading: false,

      taskisloading: false,
      taskisloading_u: false,


      selectedFile: null,
      chunkSize: 1024 * 1024, // 1MB
      uploadProgress: 0,
      uploadstatus: '',



      isupgrade: false,
      upgradedata: {
        version: "1.0.1",
        date: "2024-09-07 14:47:55",
        features: [
          "Supports URL sharing.",
        ]
      },

    };
  },
  mounted() {

    this.getupgrade();

    if (
      this.$route.params &&
      this.$route.params.dontgetmynotes == "backhomepage" &&
      this.mynotes.length > 0
    ) {
      console.log("dontgetmynotes");
    } else {
      this.getmynotes();
    }
  },
  computed: {

    filteredFiles() {
      return this.mynotes.filter(file =>
        file.notename.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    sortedNotes() {
      return [...this.filteredFiles].sort((a, b) => {
        return new Date(b.createtime) - new Date(a.createtime);
      });
    },
    notestats() {
      if (this.isloading) return 2;
      if (this.mynotes.length == 0) return 0;
      if (this.sortedNotes.length == 0) return 1;

      return 3;
    },
  },
  methods: {
    ceshi() {
      this.$refs.uploadchaoxian.openModal();
    },
    async startUpload(justment, ufile, name) {

      this.uploadstatus = 'uploading';
      if (!ufile) return;

      let retitle = this.recordtitle;
      let splitname = ufile.name;

      if (justment == 'audio') {
        retitle = name
        splitname = ufile.name;
      }
      if (justment == 'radio') {
        retitle = this.recordtitle;
        splitname = name;
      }

      let oname = '';


      if (splitname != '' && retitle != '' && retitle != 'undefined') {
        // 将文件名按点分割成数组
        const parts = splitname.split('.');
        // 取数组的最后一个元素作为文件扩展名
        const fileExtension = parts.pop();
        oname = retitle + '.' + fileExtension;
      } else {
        oname = ufile.name;
      }
      const subid = this.$cookies.get("subid");
      const totalChunks = Math.ceil(ufile.size / this.chunkSize);
      let uploadedChunks = 0;
      const timefilename = Date.now();
      for (let i = 0; i < totalChunks; i++) {
        const start = i * this.chunkSize;
        const end = Math.min(ufile.size, start + this.chunkSize);
        const chunk = ufile.slice(start, end);

        const formData = new FormData();
        formData.append('file', chunk, oname);
        formData.append('chunk_number', i);
        formData.append('total_chunks', totalChunks);
        formData.append('timefilename', timefilename);
        formData.append('subid', subid);

        try {
          const redata = await this.$axios.post('/api/upload_chunk/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          uploadedChunks++;
          this.uploadProgress = Math.round((uploadedChunks / totalChunks) * 100);

          if (redata.data.code == 200) {
            console.log("upload chunk success");
          }
          if (redata.data.code == 401) {
            this.$message({
              message: redata.data.messages,
              type: 'error',
            });
            this.$refs.uploadchaoxian.openModal();
            // break;
            uploadedChunks = 0;
            this.uploadProgress = 0;
            this.uploadstatus = 'isuploading';
            break;
          }
        } catch (error) {
          console.error('上传分片失败:', error);
          // 重试
          i--;
          if (i < totalChunks - 1) continue;
        }
      }
      if (this.uploadstatus == 'isuploading') {
        return;
      }


      if (uploadedChunks == totalChunks) {
        try {
          // 通知后端所有分片已上传完成
          const uploadover = await this.$axios.post('/api/complete_upload/', {
            filename: oname,
            total_chunks: totalChunks,
            subject: this.chooseclass,
            languagein: this.languagein,
            language: this.languagename,
            subid: subid,
            timefilename: timefilename,
          }, {
            timeout: 90000, // 设置超时时间为 60 秒
          });
          this.handleSuccess(uploadover.data);
        } catch (error) {
          this.handleError(error);

        }
      } else {
        this.$message({
          message: "Upload failed, please try again",
          type: 'error',
        });
      }

    },

    async getminfo() {
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/getminfo/", {
          subid: subid,
        });
        const resdata = response.data;
        if (resdata.code == 200) {
          this.minfo = resdata.minfo;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    copyemail() {
      const emailfeed = "easynote_ai@hotmail.com";
      const input = document.createElement("input");
      input.value = emailfeed;

      // 将 input 元素添加到文档中
      document.body.appendChild(input);

      // 选中 input 中的内容
      input.select();

      // 执行复制命令
      document.execCommand("copy");

      // 移除临时的 input 元素
      document.body.removeChild(input);

      // 提示用户复制成功
      this.$message({
        message: "Mailbox copied to clipboard",
        type: "success",
      });
    },
    chooeselanguage(language, languagename) {
      this.language = language;
      this.languagename = languagename;
      console.log(this.language, this.languagename, "homepage");
    },
    chooeselanguagein(language, languagename) {
      this.languagein = language;
      this.languagenamein = languagename;
      console.log(this.languagein, this.languagenamein, "homepage_in");
    },
    setrecordtitle(title) {
      this.recordtitle = title;
      console.log(this.recordtitle, "homepage_title");
    },
    async getmynotes() {
      this.isloading = true;
      //获取用户笔记
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/getmynotes/", {
          subid: subid,
        });
        const resdata = response.data;
        this.isloading = false;
        if (resdata.code == 200) {
          this.mynotes = resdata.mynotes;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    backhomepage() {
      this.showhome = "";
    },
    selectclass(item) {
      this.chooseclass = item.title;
      // 创建一个映射对象，用于根据chooesmodel的值找到对应的组件引用
      const modalRefs = {
        audio: this.$refs.pAudio,
        video: this.$refs.pVideo,
        upload: this.$refs.pUpload,
      };
      // 使用chooesmodel的值从映射对象中查找对应的组件引用，并调用其openModal方法
      // console.log(item, this.chooesmodel, modalRefs[this.chooesmodel]);
      if (modalRefs[this.chooesmodel]) {
        modalRefs[this.chooesmodel].openModal();
      }
    },
    chooesSub(model) {
      this.chooesmodel = model;
      this.$refs.modal.openModal();
    },
    opennotes(note, mode = '') {
      this.$router.push({
        name: "notesPage",
        params: { note: note, mode: mode },
      });
    },
    home_chooseaside(key) {
      console.log("home_chooseaside", key);
      this.showhome = key;
    },
    async createnotevideo(linksrc) {
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/createnotevideo/", {
          linksrc: linksrc,
          subject: this.chooseclass,
          subid: subid,
          language: this.languagename,
        });
        this.handleSuccess(response.data);
      } catch (error) {
        this.$message.error("Note creation failed.");
        console.error("Error:", error);
      }
    },
    async uploadFile(file, name) {
      const formData = new FormData();
      if (name) {
        formData.append("file", file, name);
      } else {
        formData.append("file", file);
      }
      formData.append("subject", this.chooseclass);
      formData.append("language", this.languagename);
      const subid = this.$cookies.get("subid");
      formData.append("subid", subid);
      try {
        const response = await this.$axios.post("/api/upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.handleSuccess(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
    gopaypage() {
      this.$router.push({
        name: "IndexPage", // 目标路由的名称
        params: { pagekey: "price" }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    handleSuccess(response) {
      if(response.code == 401){
        this.$message.error(response.messages);
        this.taskisloading = false;
        this.taskisloading_u = false;
        return;
      }
      if (response.code == 203) {
        this.$message.error(response.messages);
        this.uploadstatus = 'error';
        return;
      }
      if (response.code == 201) {
        this.taskisloading = false;
        this.taskisloading_u = false;
        this.$message.error(response.messages);
        this.uploadstatus = 'error';
        return;
      }
      if (response.code == 205) {
        this.taskisloading = false;
        this.taskisloading_u = false;
        this.uploadstatus = 'error';
        this.$refs.uploadchaoxian.openModal();
        return;
      }
      if (response.code == 303) {
        this.$message({
          message: response.messages,
          type: 'error',
          duration: 6000 // 5000 毫秒（5秒）
        });
        this.$refs.vipchaoxian.openModal();
        return;
      }

      const noteid = response.noteid;
      if (!noteid) {
        this.$message({
          message: "Note creation failed. Please save the recording file in time!",
          type: 'error',
          duration: 6000 // 5000 毫秒（5秒）
        });
        this.uploadstatus = 'error';
        return;
      }

      this.$message.success("File uploaded successfully!");
      // 上传成功
      // 关闭弹窗
      this.$refs.pAudio.closeModal();
      this.$refs.pVideo.closeModal();
      this.$refs.pUpload.closeModal();
      this.showhome = "";
      // 更新笔记列表
      // const resnote = { title: "测试笔记名称", date: "Jul 9, 2024" };
      // this.mynotes.push(resnote);
      // console.log("Response:", response);
      this.$router.push({
        name: "notesPage",
        params: { noteid: noteid, status: 10 },
      });

    },
    handleError(error) {
      alert("File upload failed.");
      this.uploadstatus = 'error';
      console.error("Error:", error);
    },
    getCodeByName(name) {
      const language = this.languages.find((lang) => lang.name === name);
      return language ? language.code : "EN";
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "long", // 添加星期几
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return date.toLocaleString("en-US", options);
    },

    async getupgrade() {
      try {
        const response = await this.$axios.post("/api/getupgrade/", {});
        const resdata = response.data;
        if (resdata.code == 200) {
          const getupgrade = resdata.data;
          const nowwversion = localStorage.getItem("version");
          if (getupgrade.version != nowwversion) {
            localStorage.setItem("version", getupgrade.version);
            this.upgradedata = getupgrade;
            this.isupgrade = true;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/styles/hompage.less";
</style>
