<template>
  <div class="note-transcript">
    <h1>Transcript</h1>
    <div ref="contentToDownload" class="transcripts">
      <p>
        {{ transcript.value }}
      </p>
    </div>
    <div>
      <!-- <el-button @click="downloadNotes" class="el-icon-upload2 export">Export</el-button> -->
      <el-dropdown class="export" @command="handleCommand">
        <span class="el-dropdown-link">
          Copy or Share<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="copytext" icon="el-icon-tickets">Copy as TXT</el-dropdown-item>
          <el-dropdown-item command="downloadaspdf" icon="el-icon-download">Download as
            PDF</el-dropdown-item>
          <el-dropdown-item command="noteshare" icon="el-icon-share">Share my Notes</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    transcript: {
      type: Object,
      default: () => ({
        // 这里填入默认对象的属性
        text: "",
        status: "",
      }),
    },
  },
  name: "NoteTranscript",
  data() {
    return {
      noteTitle: "Lawful Interception in Telecommunications Networks Overview",
      isPublic: true,
      dateUploaded: "2024-07-12",
      classSubject: "Computer Science",
    };
  },
  methods: {
    deleteNotes() {
      // 实现删除笔记的逻辑
      console.log("Deleting notes...");
    },
    handleCommand(command) {
      switch (command) {
        case "copymarkdown":
          this.copymarkdown();
          break;
        case "copytext":
          this.copytext();
          break;
        case "downloadaspdf":
          this.downloadaspdf();
          break;
        case "noteshare":
          this.noteshare();
          break;
        default:
          break;
      }
    },
    copymarkdown() {
      console.log("copymarkdown", this.notes);
      const markdownContent = this.notes.mk_notes;
      this.copyToClipboard(markdownContent);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    copytext() {
      const textContent = this.transcript.value;
      this.copyToClipboard(textContent);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    downloadaspdf() {
      // this.generateA4PaginatedPdf();
      this.printPdf();
    },
    async printPdf() {
      try {
        // 获取打印容器
        const printContainer = this.$refs.contentToDownload;
        if (!printContainer || !printContainer.innerHTML.trim()) {
          throw new Error('No content to print');
        }

        // 创建一个隐藏的 iframe
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        iframe.style.left = '-9999px';
        document.body.appendChild(iframe);

        // 将打印容器的内容复制到 iframe 中
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(`
      <html>
        <head>
          <title>打印内容</title>
          <style>
            /* 添加你需要的样式 */
            body {
              font-family: Arial, sans-serif;
            }
            .print-container {
              padding: 20px;
            }
            ${this.getAdditionalStyles()} /* 获取额外需要的样式 */
          </style>
        </head>
        <body>
          ${printContainer.innerHTML}
        </body>
      </html>
    `);
        iframeDocument.close();

        // 等待 iframe 加载完成
        await new Promise((resolve) => {
          iframe.onload = resolve;
        });

        // 等待一小段时间，确保所有内容都已渲染
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // 调用浏览器的打印功能
        iframe.contentWindow.print();

        // 移除 iframe
        document.body.removeChild(iframe);
      } catch (error) {
        console.error('PDF打印失败:', error);
        alert('PDF打印失败，请稍后重试或联系支持人员。');
      }
    },
    noteshare() {
      console.log("noteshare", this.transcript);
      const shareurl = 'https://www.easynoteai.com/share?t=' + this.transcript.notetoken;
      this.copyToClipboard(shareurl);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
          console.log('Text copied to clipboard');
        }).catch((err) => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // 使用旧的方法复制到剪贴板
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          console.log('Text copied to clipboard');
        } catch (err) {
          console.error('Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    },
    // 获取额外需要的样式
    getAdditionalStyles() {
      // 这里可以返回任何额外需要的CSS
      return `
    /* 额外的CSS样式 */
    .custom-class {
      color: #333;
    }
  `;
    },

  },
};
</script>
<style lang="less" scoped>
.note-transcript {
  color: #ffffff;
  padding: 0 2vw;
  font-family: Arial, sans-serif;

  .export {
    position: absolute;
    right: 2.4vw;
    top: 2.6vw;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    font-size: 1vw;
    padding: 0.4vw 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  h1 {
    font-size: 36px;
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
      font-size: 44px;
    }
  }

  .transcripts {
    P {
      line-height: 28px;
      font-size: 16px;
    }
  }
}
</style>
