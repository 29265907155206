<template>
    <div v-if="showModal" class="modal-overlay">
        <div class="modal-content">
            <h2>Feature Updates Vol: {{ upgradedata.version }}</h2>
            <p class="date">{{ showdate }}</p>
            <p>Thank you for choosing EasyNoteAl. Discover the latest version. </p>
            <h3>Updated Features:</h3>
            <ul>
                <li v-for="fe in upgradedata.features" :key="fe">{{ fe }}</li>

            </ul>

            <p>We're excited to continue improving our product and providing you with even more valuable features. Thank
                you for your support!</p>
            <button @click="closeModal" class="modal-button">Get it now</button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        upgradedata: Object,
    },
    data() {
        return {
            showModal: true, // 控制弹窗是否显示
        };
    },
    computed: {
        showdate() {
            return this.formatDateTime(this.upgradedata.date); // 判断是否显示弹窗
        },
    },
    methods: {
        closeModal() {
            this.showModal = false; // 点击按钮后关闭弹窗
            window.location.reload();

        },
        formatDateTime(dateTime) {
            const date = new Date(dateTime);
            const options = {
                weekday: "long", // 添加星期几
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
            };
            return date.toLocaleString("en-US", options);
        },
    },
};
</script>
<style lang="less">
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #2d2d37;
    color: #ffffff;
    padding: 1.5vw;
    border-radius: 8px;
    max-width: 25vw;

    text-align: left;

    h2 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
    }

    .date {
        color: #9f9f9f;
        margin-top: 4px;
        margin-bottom: 12px;
        font-size: 14px;
    }

    h3 {
        font-size: 18px;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    ul {
        margin: 0;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0.5vw 1.5vw;
        border-radius: 6px;
        max-height: 18vh;
        overflow-y: auto;

        li {
            margin-bottom: 6px;
        }
    }

    p {
        margin-top: 12px;
    }

    .modal-button {
        display: block;
        width: 100%;
        padding: 10px 0;
        margin-top: 20px;
        background-color: #4a80f5;
        color: #fff;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 16px;
        text-align: center;

        &:hover {
            background-color: #3a6ddb;
        }
    }
}
</style>