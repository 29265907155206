<template>
    <div class="modal-chaoxian" v-if="isVisible">
        <div class="chaoxian-content">
            <div class="chaoxian-header">
                <h2 v-html="textcontent" ></h2>
            </div>
            <div class="okbox">
                <button class="generate-button" @click="closeModal">
                    OK
                </button>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    props: {
        textcontent: {
            type: String,
            default: "",
        },
    },
    components: {
    },
    data() {
        return {
            isVisible: false,
        };
    },
    methods: {
        openModal() {
            this.isVisible = true;
        },
        closeModal() {
            this.isVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
.modal-chaoxian {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .chaoxian-content {
        background-color: rgb(56, 53, 74);
        border-radius: 10px;
        padding: 2vw;
    }

    .chaoxian-header {
        text-align: center;
        margin-bottom: 20px;

        h2 {
            color: white;
            font-size: 1.2vw;
            margin: 25px 0px;
        }
    }

    .okbox{
        text-align: right;
    }

    .generate-button {
        background-color: rgb(104 35 255);
        color: white;
        border: none;
        border-radius: 8px;
        padding: 0.6vw 1vw;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #6d28d9;
        }
    }
}
</style>